import { Center, Spinner } from "@givenwell/components";
import { createBrowserHistory, createRouter } from "@tanstack/react-router";
import { appRoute } from "./pages/(app)/+route";
import { bookmarksRoute } from "./pages/(app)/bookmarks";
import { claimsIndexRoute } from "./pages/(app)/claims/+index";
import { claimsRoute } from "./pages/(app)/claims/+route";
import { claimRoute } from "./pages/(app)/claims/[claimId]/+route";
import { newClaimRoute } from "./pages/(app)/claims/new/+route";
import { claimSettingsRoute } from "./pages/(app)/claims/settings/+route";
import { setupBankAcctRoute } from "./pages/(app)/claims/setup/+route";
import { marketplaceIndexRoute } from "./pages/(app)/marketplace/+index";
import { marketplaceRoute } from "./pages/(app)/marketplace/+route";
import { listingRoute } from "./pages/(app)/marketplace/listings/$listingId/+route";
import { purchaseIndexRoute, purchaseRoute } from "./pages/(app)/marketplace/purchase/+route";
import { supplierRoute } from "./pages/(app)/marketplace/suppliers/$supplierId/+route";
import { messageRoute } from "./pages/(app)/messages/$conversationId/+route";
import { messagesIndexRoute } from "./pages/(app)/messages/+index";
import { messagesRoute } from "./pages/(app)/messages/+route";
import { cancelPurchaseRoute } from "./pages/(app)/purchases/$purchaseId/cancel/+route";
import { purchasesIndexRoute } from "./pages/(app)/purchases/+index";
import { purchasesRoute } from "./pages/(app)/purchases/+route";
import { cancelSubscriptionRoute } from "./pages/(app)/purchases/subscriptions/$subscriptionId/cancel/+route";
import { resourceRoute } from "./pages/(app)/resources/$resourceId/+route";
import { resourcesIndexRoute } from "./pages/(app)/resources/+index";
import { resourcesRoute } from "./pages/(app)/resources/+route";
import { settingsRoute } from "./pages/(app)/settings/+route";
import { topUpIndexRoute } from "./pages/(app)/top-up/+index";
import { topUpRoute } from "./pages/(app)/top-up/+route";
import { checkoutRoute } from "./pages/(app)/top-up/checkout/+route";
import { topUpCompleteRoute } from "./pages/(app)/top-up/complete/+route";
import { walletIndexRoute } from "./pages/(app)/wallet/+index";
import { walletRoute } from "./pages/(app)/wallet/+route";
import { expiringTokensRoute } from "./pages/(app)/wallet/expiring/+route";
import { ErrorComponent } from "./pages/+500";
import { indexRoute } from "./pages/+index";
import { authRoute, rootRoute } from "./pages/__root";
import { demoRoute } from "./pages/demo/+route";
import { forgotPasswordRoute } from "./pages/forgot-password/+route";
import { activateGiftInvitationRoute } from "./pages/gift/activate/+route";
import { activateInvitationRoute } from "./pages/invitation/activate/+route";
import { mergeAccountRoute } from "./pages/merge-account/+route";
import { newAccountRoute } from "./pages/new-account/+route";
import { nominateSupplierRoute } from "./pages/nominate/+route";
import { termsPage } from "./pages/policies/terms/+route";
import { signInRoute } from "./pages/sign-in/+route";

// prettier-ignore
const routeTree = rootRoute.addChildren({
  authRoute: authRoute.addChildren({
    appRoute: appRoute.addChildren({
      bookmarksRoute,
      claimsRoute: claimsRoute.addChildren({
        claimsIndexRoute,
        newClaimRoute,
        claimRoute,
        setupBankAcctRoute,
        claimSettingsRoute,
      }),
      marketplaceRoute: marketplaceRoute.addChildren({
        marketplaceIndexRoute,
        listingRoute,
        purchaseRoute: purchaseRoute.addChildren({
          purchaseIndexRoute,
        }),
      }),
      messagesRoute: messagesRoute.addChildren({
        messagesIndexRoute, 
        messageRoute,
      }),
      purchasesRoute: purchasesRoute.addChildren({
        purchasesIndexRoute,
        cancelPurchaseRoute,
        cancelSubscriptionRoute,
      }),
      resourcesRoute: resourcesRoute.addChildren({
        resourcesIndexRoute, 
        resourceRoute
      }),
      settingsRoute,
      supplierRoute,
      topUpRoute: topUpRoute.addChildren({
        topUpIndexRoute,
        checkoutRoute,
        topUpCompleteRoute,
      }),
      walletRoute: walletRoute.addChildren({
        walletIndexRoute,
        expiringTokensRoute,
      }),
    }),

    mergeAccountRoute,
  }),
  termsPage,
  newAccountRoute,
  activateInvitationRoute,
  activateGiftInvitationRoute,
  indexRoute,
  signInRoute,
  forgotPasswordRoute,
  demoRoute,
  nominateSupplierRoute,
});

const history = createBrowserHistory();

export const router = createRouter({
  routeTree,
  history,
  context: {
    conditionalTopBar: false,
  },
  notFoundMode: "fuzzy",
  defaultErrorComponent: ErrorComponent,
  defaultPendingMs: 0,
  defaultPendingComponent: () => (
    <Center css={{ flex: "1 0 0px" }}>
      <Spinner />
    </Center>
  ),
  defaultPreload: "intent",
  defaultPreloadDelay: 100,
  unmaskOnReload: true,
});

if (import.meta.env.PROD) {
  router.subscribe("onResolved", event => {
    if (event.pathChanged) {
      import("./third-party")
        .then(module => {
          module.capturePageView(window.location.origin + event.toLocation.href);
        })
        .catch(console.error);
    }
  });
}

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
